import { render, staticRenderFns } from "./AccountExpirationWarning.vue?vue&type=template&id=edd4a706&scoped=true&"
import script from "./AccountExpirationWarning.vue?vue&type=script&lang=js&"
export * from "./AccountExpirationWarning.vue?vue&type=script&lang=js&"
import style0 from "./AccountExpirationWarning.vue?vue&type=style&index=0&id=edd4a706&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edd4a706",
  null
  
)

component.options.__file = "AccountExpirationWarning.vue"
export default component.exports